<div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <img src="./assets/logo/logo-tisco.png" class="object-contain h-[90px] mx-auto" alt="">
    <div class="loader mx-auto mt-4"></div>

    @if(message) {
        <div class="mt-5 h4 text-center">
            {{ message }}
        </div>
    }
</div>
